import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImagesService {
  constructor() {}

  getLogoImage() {
    const currentDate = new Date();
    const currentDay = currentDate.getUTCDate();
    const currentMonth = currentDate.getUTCMonth() + 1;
    const currentYear = currentDate.getUTCFullYear();

    // eslint-disable-next-line prefer-const
    let season: {
      bubbles: string[];
      mermaidSkin: string;
      mermaidMob: string;
      merbaidTable: string;
    } = {
      bubbles: [
        'https://cdn.chipychapa.com//welle/General_site/Animation/1.png',
        'https://cdn.chipychapa.com//welle/General_site/Animation/2.png',
        'https://cdn.chipychapa.com//welle/General_site/Animation/3.png',
        'https://cdn.chipychapa.com//welle/General_site/Animation/4.png',
        'https://cdn.chipychapa.com//welle/General_site/Animation/5.png',
      ],
      mermaidMob: 'https://cdn.chipychapa.com/welle/General_site/MainPageBanner/static_banners/mobile/default.png',
      merbaidTable: 'https://cdn.chipychapa.com/welle/General_site/MainPageBanner/static_banners/tablet/default.png',
      mermaidSkin: 'Base',
    };

    const specialSeasons = [
      {
        condition: () => currentMonth === 10 || (currentMonth === 11 && currentDay <= 2),
        updates: {
          merbaidTable: '', //todo
          mermaidMob: 'https://cdn.chipychapa.com/welle/General_site/MainPageBanner/static_banners/mobile/default.png',
          bubbles: [
            'https://cdn.chipychapa.com//welle/General_site/Background/halloween/Bubble1.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/halloween/Bubble2.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/halloween/Bubble3.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/halloween/Bubble4.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/halloween/Bubble5.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/halloween/Bubble6.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/halloween/Bubble7.svg',
          ],
          mermaidSkin: 'Base', //todo
        },
      },
      {
        condition: () => currentMonth === 12 || (currentMonth === 1 && currentDay === 1),
        updates: {
          mermaidMob: '', //todo
          merbaidTable: '', //todo
          bubbles: [
            'https://cdn.chipychapa.com//welle/General_site/Background/winter/1.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/winter/2.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/winter/3.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/winter/4.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/winter/5.svg',
          ],
          mermaidSkin: 'Winter',
        },
      },
      {
        condition: () => currentMonth === 2 && currentDay >= 7 && currentDay <= 15,

        updates: {
          mermaidMob:
            'https://cdn.chipychapa.com/welle/General_site/MainPageBanner/static_banners/mobile/valentine.png',
          merbaidTable:
            'https://cdn.chipychapa.com/welle/General_site/MainPageBanner/static_banners/tablet/valentine.png',
          bubbles: [
            'https://cdn.chipychapa.com//welle/General_site/Background/valentine_day/1bubble.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/valentine_day/2bubble.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/valentine_day/3bubble.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/valentine_day/4bubble.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/valentine_day/5bubble.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/valentine_day/6bubble.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/valentine_day/7bubble.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/valentine_day/8bubble.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/valentine_day/9bubble.svg',
          ],
          mermaidSkin: 'Valentine',
        },
      },
      {
        condition: () => currentMonth === 3 && currentDay >= 17 && currentDay <= 21,
        updates: {
          mermaidMob:
            'https://cdn.chipychapa.com/welle/General_site/MainPageBanner/static_banners/mobile/stpatrick.png',
          merbaidTable:
            'https://cdn.chipychapa.com/welle/General_site/MainPageBanner/static_banners/tablet/st_patrick.png ',
          bubbles: [
            'https://cdn.chipychapa.com//welle/General_site/Background/st_patrick/Bubble1.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/st_patrick/Bubble2.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/st_patrick/Bubble3.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/st_patrick/Bubble4.svg',
          ],
          mermaidSkin: 'Patrick',
        },
      },
      {
        condition: () => currentMonth === 4 && currentDay >= 14 && currentDay <= 20 && currentYear === 2025,
        updates: {
          mermaidMob: 'https://cdn.chipychapa.com/welle/General_site/MainPageBanner/static_banners/mobile/easter.png',
          merbaidTable:
            ' https://cdn.chipychapa.com/welle/General_site/MainPageBanner/static_banners/tablet/easter.png',
          logoImage: 'https://cdn.playasballs.com//wazbee/Logo/bee_easter.svg',
          bubbles: [
            'https://cdn.chipychapa.com//welle/General_site/Background/easter/Bubble1.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/easter/Bubble2.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/easter/Bubble3.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/easter/Bubble4.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/easter/Bubble5.svg',
            'https://cdn.chipychapa.com//welle/General_site/Background/easter/Bubble6.svg',
          ],
          mermaidSkin: 'Easter',
        },
      },
      {
        condition: () => currentMonth === 5 && currentDay >= 9 && currentDay <= 25 && currentYear === 2025,
        updates: {
          //nhl todo
          mermaidMob: '',
          merbaidTable: '',
          bubbles: [
            'https://cdn.chipychapa.com//welle/General_site/Animation/1.png',
            'https://cdn.chipychapa.com//welle/General_site/Animation/2.png',
            'https://cdn.chipychapa.com//welle/General_site/Animation/3.png',
            'https://cdn.chipychapa.com//welle/General_site/Animation/4.png',
            'https://cdn.chipychapa.com//welle/General_site/Animation/5.png',
          ],
          mermaidSkin: 'Base',
        },
      },
    ];

    for (const seasonEvent of specialSeasons) {
      if (seasonEvent.condition()) {
        Object.assign(season, seasonEvent.updates);
        break;
      }
    }

    return season;
  }
}
